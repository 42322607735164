import {serializeCheckoutForm, serialize} from './springSerialize';
import { backendFieldMapping } from '../component/checkout/fieldMapping';
import { fadeOut, scrollToElement } from "../utilities/helpers";
import { querySelectValue } from './helpers';

declare var validationMessages: [];
declare var urlPrefix: any;

export class Validation {

  initValidate() {
    let errors = 0;
    let commissionCheck = '';
    let forms = document.querySelectorAll('form.validateForm');
    forms = [].slice.call(forms);
    if (forms) {
      forms.forEach((form: HTMLFormElement) => {
        let inputs = form.querySelectorAll('input');
        const commission = document.getElementById('ftlData');
        commissionCheck = commission && commission.getAttribute('data-dtmpage');
        inputs = [].slice.call(inputs);

        if (form.querySelector('.dropdown-industry .select')) {
          const dropdown = form.querySelector('.dropdown-industry .select');
          dropdown.addEventListener('click', () => {
            const error = dropdown.parentNode.querySelector('.input-field-error');
            if (error) error.remove();
          });
        }

        inputs.forEach((el: HTMLInputElement) => onFieldListener(el, form));

        const submitButtonElement: HTMLButtonElement = form.querySelector('button[type="submit"]');
        submitButtonElement?.addEventListener('click', () => inputs.forEach(element => onFieldValidation(element, form)));

        form.addEventListener('submit', async (event: Event) => {
          // only prevent submit if not PDF or Payment pending
          (form.className.includes('pdfAddress') || commissionCheck === 'payment-pending') ? '' : event.preventDefault();
          errors = 0;
          inputs.forEach((el: HTMLInputElement) => validateCases(el, form));

          // Check the delivery country first. If false, show the delivery address form and prevent the user from proceeding the form
          const fieldDeliveryAddressCheckboxField: HTMLInputElement = form.querySelector('#input-field-deliveryAddress-enabled');
          if (!await isDeliveryCountryValid(form) && fieldDeliveryAddressCheckboxField?.checked === false) {

            event.preventDefault();
            enableDeliveryAddress(form);

            return;
          }

          if (errors > 0) {
            event.preventDefault();

            if(form.id !== 'form-newsletter-widget') {
              showError(form);
            }
          } else if (form.id === 'form-checkout-address' && !form.className.includes('pdfAddress')) {
            const email = querySelectValue(form, 'input[name="login.email"]');
            const password = querySelectValue(form, 'input[name="identity.password"]');
            const loginEnabled: HTMLInputElement = form.querySelector('[name="login.enabled"]');
            const isGuest = (loginEnabled && loginEnabled.checked) ? 0 : 1;

            // URL for redirect after successful XHR response
            const GOTO_URL = form.querySelector('#submitUrl').getAttribute('name');

            const XHR = new XMLHttpRequest();
            XHR.onreadystatechange = () => {
              if (XHR.readyState === XMLHttpRequest.DONE && XHR.status === 200) {
                isGuest === 1 ? window.location.href = GOTO_URL : login(email, password, GOTO_URL);
              } else {
                backendRespones(XHR, form);
              }
            }
            XHR.open('POST', form.getAttribute('action'));
            XHR.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
            // only checkout form
            XHR.send(serializeCheckoutForm(form));
          } else if (form.id === 'form-checkout-payment') {
            const paymentMethodName = form.querySelector('input[name=paymentMethodId]:checked').getAttribute('data-payment-name');
            const paymentMethodBonityCheck = form.querySelector('input[name=paymentMethodId]:checked').getAttribute('data-payment-creditstatus-check');

            // show error if no paymentMethod is selected
            if (paymentMethodName === '') {
              (<HTMLInputElement>form.querySelector('#input-field-paymentMethodId-error')).style.display = 'block';
              return false;
            }
            (<HTMLInputElement>form.querySelector('#input-field-paymentMethodId-error')).style.display = 'none';

            submitPayment(form);
          } else if (form.id === 'order-confirmation-feedback-form') {
            event.preventDefault();

            let formData = new FormData(form);

            const XHR = new XMLHttpRequest();

            XHR.open( 'POST', urlPrefix + '/cms/externa' +
                'l/error-page/messages/save' );
            XHR.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            XHR.onload = function () {
              const response = XHR.getResponseHeader('location');

              if (XHR.readyState == 4 && XHR.status == 200) {
                const button = (<HTMLButtonElement>document.getElementById('sendFeedback'));
                button.disabled = true;
                setTimeout(function() {
                  let w1 = button.querySelector('.mp-done').getBoundingClientRect().width,
                      w2 = button.querySelector('.mp-pending').getBoundingClientRect().width;
                  if(w1 > w2) {
                    button.style.width = button.getBoundingClientRect().width + (w1 - w2) + 'px';
                  }
                  button.classList.add('feedback--sent');
                  form.classList.add('form--disabled');
                }, 1400);
              }
            }
            XHR.send(formData);
          } else if (form.id === 'form-newsletter-widget') {
            event.preventDefault();

            const formInputs = Array.from(form.querySelectorAll('input'));
            let serialString = '';
            formInputs.forEach((input, index) => {
              if(input.type === 'radio' || input.type === 'checkbox') {
                if(input.checked) {
                  serialString += encodeURIComponent(input.name) + '=' + encodeURIComponent(input.value.toString());
                  ++index < formInputs.length ? serialString += '&' : null;
                }
              } else {
                serialString += encodeURIComponent(input.name) + '=' + encodeURIComponent(input.value.toString());
                ++index < formInputs.length ? serialString += '&' : null;
              }
            })

            const XHR = new XMLHttpRequest();
            XHR.open( 'POST', form.action );
            XHR.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            XHR.onload = function () {

              const response = JSON.parse(this.responseText);
              if(response.status == 'ERROR') {
                form.classList.add('display-error');
              } else if (response.status == 'OK') {
                form.classList.remove('display-error');
                window.location.href = response.confirmationPageUrl;
              }
            }
            XHR.send(serialString);
          } else if (form.id === 'form-checkout-confirmation' || form.id === 'registration' || form.id.indexOf('address')) {
            document.createElement('form').submit.call(form);
          }
        });
      })
    }

    function validateCases(input: HTMLInputElement, form: HTMLFormElement) {
      // check if field is not hidden
      if (input.offsetParent !== null) {
        const error = input.parentNode.querySelector('.input-field-error');
        if (error) error.remove();
        if (input.type === 'password') return pwdCheck(input, form);
        // check if field is required
        if (input.name === 'rating') return ratingCheck(input);
        if (input.hasAttribute('req') && input.hasAttribute('type')) {
          if (input.value === '' || input.value === null) return isEmpty(input);
          if (input.type === 'radio' || input.type === 'checkbox') return checkboxCheck(input);
        }
        if (input.name === 'organisation.customerNumber') return customerNrCheck(input);
        if (input.type === 'email') return emailCheck(input);
        if (input.name === 'fax') return phoneCheck(input);
        if (input.name === 'phone') return phoneCheck(input);
        if (input.name === 'identity.phone') return phoneCheck(input);
        if (input.name === 'name') return lengthCheck(input, 40);
        if (input.name === 'name2') return lengthCheck(input, 40);
        if (input.name === 'nameAdditional') return lengthCheck(input, 40);
        if (input.name === 'organisation.name') return companyNameCheck(input);
        if (input.name === 'iban') return ibanCheck(input);
        if (input.name.includes('street')) return streetCheck(input);
        if (input.name.includes('postalCode')) return plzCheck(input);
        if (input.name.includes('vatId')) return vatIdCheck(input);
        lengthCheck(input);
      }
    }

    function onFieldListener(input: HTMLInputElement, form: HTMLFormElement) {
      if (input.value !== '') input.parentElement.classList.add('valid');
      input.addEventListener('focusin', () => {
        reset(input);
      });
      input.addEventListener('change', () => {
        onFieldValidation(input, form);
      });
      input.addEventListener('focusout', () => {
        onFieldValidation(input, form);
      });
    }

    function reset(input: HTMLInputElement) {
      const error = input.parentNode.querySelectorAll('.input-field-error');
      if (error) error.forEach(el => el.parentNode.removeChild(el));
      input.parentElement.classList.remove('valid');
    }

    function onFieldValidation(input: HTMLInputElement, form: HTMLFormElement) {
      reset(input);
      errors = 0;
      if(input.readOnly) return;

      if (input.name.includes('street')) streetCheck(input);
      if (input.name.includes('postalCode')) plzCheck(input);
      if (input.name.includes('vatId')) vatIdCheck(input);

      switch (input.type) {
        case 'email':
          emailCheck(input);
          break;
        case 'password':
          pwdCheck(input, form);
          break;
      }

      switch (input.name) {
        case 'organisation.customerNumber':
          customerNrCheck(input);
          break;

        case 'phone':
        case 'identity.phone':
        case 'fax':
          phoneCheck(input);
          break;

        case 'organisation.name':
          companyNameCheck(input);
          break;

        case 'iban':
          ibanCheck(input);
          break;

        case 'rating':
          ratingCheck(input);
          break;

        case 'name':
        case 'name2':
        case 'nameAdditional':
          lengthCheck(input, 40);
          break;
        default:
          lengthCheck(input);
      }

      if (errors === 0 && input.value !== '') input.parentElement.classList.add('valid');
    }

    function pwdCheck(password: HTMLInputElement, form: HTMLFormElement) {
      if (password.value === '' || password.value == null) return isEmpty(password);
      const passwords: any = form.querySelectorAll('[type="password"]');
      if (password.value.length < 8) {
        errors++;
        setError(password, 3);
      } else if (passwords[1].value !== '' && passwords[0].value !== passwords[1].value) {
        passwords.forEach((pass: HTMLInputElement) => reset(pass))
        errors++;
        setError(password, 7);
      }
    }

    function emailCheck(email: HTMLInputElement) {
      const regExp = /^(?:[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-])+@(?:[a-zA-Z0-9]|[^\u0000-\u007F])(?:(?:[a-zA-Z0-9-]|[^\u0000-\u007F]){0,61}(?:[a-zA-Z0-9]|[^\u0000-\u007F]))?(?:\.(?:[a-zA-Z0-9]|[^\u0000-\u007F])(?:(?:[a-zA-Z0-9-]|[^\u0000-\u007F]){0,61}(?:[a-zA-Z0-9]|[^\u0000-\u007F]))?)*$/;
      if (!regExp.test(email.value)) {
        errors++;
        setError(email, 1);
      }
    }

    function phoneCheck(phone: HTMLInputElement) {
      const regExp = /^[\d+\-.()\/\s]{0,16}$/;
      if (!regExp.test(phone.value)) {
        errors++;
        setError(phone, 2);
      }
    }

    function ratingCheck(input: HTMLInputElement) {
      const regExp = /^[1-5]*$/;
      if (!regExp.test(input.value) || input.value === '' || input.value === null) {
        errors++;
        setError(input, 23);
      }
    }

    function lengthCheck(input: HTMLInputElement, maxLength = 60) {
      if (input.value.length > maxLength) {
        errors++;
        if (maxLength === 60) {
          setError(input, 4);
        } else {
          setError(input, 28);
        }
      }
    }

    function companyNameCheck(input: HTMLInputElement) {
      const regExp = /^(?!.*\x60).*$/;
      if (!regExp.test(input.value)) {
        errors++;
        setError(input, 5);
      }
    }

    function customerNrCheck(input: HTMLInputElement) {
      const regExp = /^[0-9]*$/;
      if (!regExp.test(input.value)) {
        errors++;
        setError(input, 6);
      }
    }

    function streetCheck(input: HTMLInputElement) {
      const country: HTMLInputElement = input.closest('fieldset').querySelector('input[id*="countryCode"]');
      const countryCode: string = country.value;
      const regExp = /^(?=^.{0,60}$)((?!packstation).)+ \d+\.*[a-zA-Z0-9_ -]*\/*\w*$/i;
      const LUregExp = /^(?=^.{0,60}$)\d+.*$/i;
      const ESregExp = /^.*\d+.*$/i;
      if (countryCode === 'LU' && isRegExValid(LUregExp, input.value)) {
        errors++;
        return setError(input, 19);
      } else if (countryCode === 'ES' && isRegExValid(ESregExp, input.value)) {
        errors++;
        return setError(input, 19);
      } else if ((countryCode !== 'LU' && countryCode !== 'ES') && isRegExValid(regExp, input.value)) {
        errors++;
        return setError(input, 19);
      }
    }

    function plzCheck(input: HTMLInputElement) {
      const json = require('./postal-codes.json');
      const country: HTMLInputElement = input.closest('fieldset').querySelector('input[id*="countryCode"]');
      const countryCode: string = country.value;
      const filtered = json.filter((a: any) => a.ISO === countryCode);
      const regExp = new RegExp(filtered[0].Regex);
      const similarCountries = ['AT', 'CH', 'LI', 'BE', 'LU'];

      // if DE and 5 digits
      if (countryCode === 'DE' && !isMaxLength(5, input.value)) {
        errors++;
        return setError(input, 9);
      } else if (countryCode === 'DE' && !isMinLength(5, input.value)) {
        errors++;
        return setError(input, 8);
      }

      // if NL and custom regex
      if (countryCode === 'NL' && isRegExValid(regExp, input.value)) {
        errors++;
        return setError(input, 12);
      }

      // if country settings similar and 4 digits
      if (similarCountries.includes(countryCode) && !isMaxLength(4, input.value)) {
        errors++;
        return setError(input, 11);
      } else if (similarCountries.includes(countryCode) && !isMinLength(4, input.value)) {
        errors++;
        return setError(input, 10);
      }

      // if any available country
      if (isRegExValid(regExp, input.value)) {
        errors++;
        return setError(input, 12);
      }
    }

    function checkboxCheck(input: HTMLInputElement) {
      const parent = input.closest('.container-checkbox');
      const error = parent.querySelectorAll('.input-field-error');
      if (error) error.forEach(el => el.parentNode.removeChild(el));
      const getInputs = parent.getElementsByTagName('input');
      let inputValid = false;

      let i = 0;
      while (!inputValid && i < getInputs.length) {
        if (getInputs[i].checked) inputValid = true;
        i++;
      }

      if (!inputValid && input.type === 'radio') {
        errors++;
        setError(input, 20);
      } else if (!inputValid && (input.name.indexOf('Newsletter') !== -1)) {
        errors++;
        setError(input, 27);
      } else if (!inputValid) {
        errors++;
        setError(input, 21);
      }
    }

    function vatIdCheck(input: HTMLInputElement) {
      if (input.value) {
        const country: HTMLInputElement = input.closest('fieldset').querySelector('input[id*="countryCode"]');
        const countryCode: string = country.value;
        const DEregEx = /^DE[0-9]{9}$/i;
        const ATregEx = /^ATU[0-9]{8}$/i;
        const CHregEx = /^(CHE)?[-, ]?[0-9]{3}[\., ]?[0-9]{3}[\., ]?[0-9]{3}[ ]?(MWST|IVA|TVA)?$/i;
        const LIregEx = /^\d{5}$/;
        const BEregEx = /^[0-9]{10}$|^N.A.$/i;
        const NLregEx = /^NL[0-9]{9}B[0-9]{2}$/i;
        const LUregEx = /^LU[0-9]{8}$/i;
        const ESregEx = /^ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$/i;
        if (countryCode === 'DE' && isRegExValid(DEregEx, input.value)) {
          errors++;
          setError(input, 13);
        } else if (countryCode === 'AT' && isRegExValid(ATregEx, input.value)) {
          errors++;
          setError(input, 14);
        } else if (countryCode === 'CH' && isRegExValid(CHregEx, input.value)) {
          errors++;
          setError(input, 15);
        } else if (countryCode === 'LI' && isRegExValid(LIregEx, input.value)) {
          errors++;
          setError(input, 6);
        } else if (countryCode === 'NL' && isRegExValid(NLregEx, input.value)) {
          errors++;
          setError(input, 16);
        } else if (countryCode === 'BE' && isRegExValid(BEregEx, input.value)) {
          errors++;
          setError(input, 17);
        } else if (countryCode === 'LU' && isRegExValid(LUregEx, input.value)) {
          errors++;
          setError(input, 18);
        } else if (countryCode === 'ES' && isRegExValid(ESregEx, input.value)) {
          errors++;
          setError(input, 24);
        }
      }
    }

    async function isDeliveryCountryValid(form: HTMLFormElement): Promise<boolean> {

      const response = await fetch(urlPrefix + '/order/delivery-countries');
      const deliveryCountries = await response.json();

      const country: HTMLInputElement = form.querySelector('input[id*="countryCode"]');
      const countryCode: string = country?.value;

      return deliveryCountries?.indexOf(countryCode?.toUpperCase()) !== -1;
    }

    function ibanCheck(iban: HTMLInputElement) {
      const regExp = /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/i;
      if (!regExp.test(iban.value)) {
        errors++;
        setError(iban, 22);
      }
    }

    function isEmpty(input: HTMLInputElement) {
      errors++;
      setError(input, 0);
    }

    function isRegExValid(regex: RegExp, value: string) {
      return !regex.test(value);
    }

    function isMaxLength(length: number, value: string) {
      return value.length <= length;
    }

    function isMinLength(length: number, value: string) {
      return value.length >= length;
    }

    function setError(input: HTMLInputElement, errorIndex?: number, backend?: string) {
      const node = document.createElement('div');
      const message = backend ? backend : validationMessages[errorIndex];
      const textnode = document.createTextNode(message);
      node.appendChild(textnode);
      node.classList.add('input-field-error');
      if(input.type === 'radio') {
        input.parentNode.parentNode.appendChild(node);
      } else {
        input.parentNode.appendChild(node);
      }
    }

    function showError(form: HTMLFormElement) {
      const firstError = form.querySelector('.input-field-error');
      firstError.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }

    function login(email: string, password: string, gotoUrl: string) {
      var loginForm               = document.createElement("form");
      loginForm.method            = "post";
      loginForm.action            = window.location.origin + "/user/login";

      var loginEmailInput         = document.createElement("input");
      loginEmailInput.name        = "email";
      loginEmailInput.type        = "text";
      loginEmailInput.value       = email;
      loginForm.appendChild(loginEmailInput);

      var loginPasswordInput      = document.createElement("input");
      loginPasswordInput.name     = "password";
      loginPasswordInput.type     = "password";
      loginPasswordInput.value    = password;
      loginForm.appendChild(loginPasswordInput);

      var loginGotoUrlInput = document.createElement("input");
      loginGotoUrlInput.name      = "gotoUrl";
      loginGotoUrlInput.type      = "hidden";
      loginGotoUrlInput.value     = gotoUrl;
      loginForm.appendChild(loginGotoUrlInput);

      document.body.appendChild(loginForm);
      loginForm.submit();
    }

    function backendRespones(XHR: XMLHttpRequest, form: HTMLFormElement) {
      const getResponse = async () => {
        try {
          const responseJSON = await JSON.parse(XHR.response);
          if (responseJSON !== null && responseJSON.fieldErrors !== null) {
            let i;
            const fieldErrors = responseJSON.fieldErrors;
            for (i = 0; i < fieldErrors.length; i++) {
              let field: any = undefined;
              if (form.querySelector('input[name="' + fieldErrors[i].field + '"]') === null || fieldErrors[i].field === 'email') {
                const fieldName = backendFieldMapping[fieldErrors[i].field];
                field = form.querySelector('input[name="' + fieldName + '"]');
              } else {
                field = form.querySelector('input[name="' + fieldErrors[i].field + '"]');
              }
              const error = field.parentNode.querySelector('.input-field-error');
              if (error) error.remove();
              setError(field, 0, fieldErrors[i].message);
            }
          }
        } catch (err) {}
      }
      getResponse();
    }

    function submitPayment(form: HTMLFormElement) {
      const iban = querySelectValue(form, 'input[name=iban]');
      const accountholder = querySelectValue(form, 'input[name=accountholder]');
      const cbSaveIban = form.querySelector('input[name=saveIban]:checked');
      const ibanSelect: HTMLSelectElement = form.querySelector('#selectStoredIban');
      const ibanChoice = ibanSelect && ibanSelect.options[ibanSelect.selectedIndex].value;
      const useTrustedshop = querySelectValue(form, 'input[name=useTrustedshop]:checked');
      const trustedshopProduct = querySelectValue(form, 'input[name=trustedshopProduct]');
      const selectedIdealBank = querySelectValue(form, 'input[name=selectedIdealBank]');

      let saveIban = 0;
      let storedIban = '';
      let ibanWithStars = '';
      if (typeof(ibanSelect) !== 'undefined' && ibanSelect !== null){
        if(ibanChoice == 'new') {
          storedIban = '';
        } else {
          const storedIbanFields = ibanChoice.split('-');
          storedIban = storedIbanFields[0];
          ibanWithStars = storedIbanFields[1];
        }
      }

      cbSaveIban && (saveIban = 1);

      const paymentMethodId: any = form.querySelectorAll('[name="paymentMethodId"]');
      let checkedPaymentMethodId = '';

      for (let i = 0, length = paymentMethodId.length; i < length; i++) {
        if (paymentMethodId[i].checked) {
          checkedPaymentMethodId = paymentMethodId[i].value;
          break;
        }
      }

      if (commissionCheck !== 'payment-pending') {
        // Used by paymentPage and pendingPage - do not execute ajax call on pendingPage (commission NOT given)
        const data: any = {
          'iban': iban,
          'accountholder': accountholder,
          'paymentMethodId': checkedPaymentMethodId,
          'saveIban': saveIban,
          'useTrustedshop': useTrustedshop,
          'trustedshopProduct': trustedshopProduct,
          'storedIban': storedIban,
          'selectedIdealBank': selectedIdealBank,
          'ibanWithStars': ibanWithStars
        }
        const XHR = new XMLHttpRequest();
        XHR.onreadystatechange = () => {
          if (XHR.readyState === XMLHttpRequest.DONE && XHR.status === 200) {
            //disableButton($('.toggle-to-summary'));
            const redirectSummery = document.getElementById('form-checkout-redirect-summary');
            const paymentModal = document.getElementById('modal-payments');
            if (redirectSummery) {
              (<HTMLInputElement>document.getElementById('redirect-iban')).value = iban;
              (<HTMLInputElement>document.getElementById('redirect-accountholder')).value = accountholder;
              (<HTMLInputElement>document.getElementById('redirect-paymentMethodId')).value = checkedPaymentMethodId;
              (<HTMLInputElement>document.getElementById('redirect-storedIban')).value = storedIban;
              (<HTMLInputElement>document.getElementById('redirect-ibanWithStars')).value = ibanWithStars;
              (<HTMLInputElement>document.getElementById('redirect-selectedIdealBank')).value = selectedIdealBank;
              (<HTMLInputElement>document.getElementById('redirect-useTrustedshop')).value = useTrustedshop;
              (<HTMLInputElement>document.getElementById('redirect-trustedshopProduct')).value = trustedshopProduct;
              (<HTMLInputElement>document.getElementById('redirect-selectedIdealBank')).value = selectedIdealBank;
              (redirectSummery as HTMLFormElement).submit();
            } else if (paymentModal) {
              fadeOut(paymentModal);
              fadeOut(document.querySelector('.modal-background'));
            }
          } else {
            backendRespones(XHR, form);
          }
        }
        XHR.open('POST', window.location.origin + '/order/checkout/payment/select');
        XHR.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
        XHR.send(serialize(data));
      }
    }

    function enableDeliveryAddress(form: HTMLFormElement): void {

      const fieldDeliveryAddress: HTMLInputElement = form.querySelector('#input-field-deliveryAddress-enabled');
      const deliveryAddress: HTMLFieldSetElement = form.querySelector('#deliveryAddress');
      if (fieldDeliveryAddress && deliveryAddress) {

        fieldDeliveryAddress.checked = true;
        scrollToElement(fieldDeliveryAddress, -100);
        deliveryAddress.style.display = 'block';
      } else {
        console.warn('fieldDeliveryAddress or deliveryAddress missing')
      }
    }

  }


  init() {
    this.initValidate();
  }
}